import Card from '../Card'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../constants/colors'
import {CompanyCreditReportModel} from "../../redux/companyMonitorTypes";

interface Props {
  creditReport: CompanyCreditReportModel
}

const CompanyReportCreditScoreCard = ({creditReport}: Props) => {
  const styles = useStyles()

  return (
    <>
      <Card className={`${styles.card} card`}>
        <p>Credit score: <span>{creditReport?.creditScore}</span></p>
        <p>Probability of default (%): <span>{creditReport.probabilityOfDefaultPercent}</span></p>
      </Card>
    </>
  )
}
export default CompanyReportCreditScoreCard

const useStyles = createUseStyles({
  card: {
    height: '100%',
    backgroundColor: '#4B4B4B',
    display: 'flex',
    flex: 1,
    borderRadius: 8,
    padding: 14,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  }
})
