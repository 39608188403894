import React, {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {useAppSelector} from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import CompanyCheckList from "../components/CompanyMonitor/CompanyCheckList";
import useCompanyCheckActions, {CompanyCheckRequest} from "../hooks/useCompanyCheckActions";
import {CompanyCheckResponseModel} from "../redux/companyMonitorTypes";
import {formatDateTimeString} from "../utils/dateHelper";
import Accordion from 'react-bootstrap/Accordion';
import {CompanyCheckSearchForm} from "../components/CompanyMonitor/CompanyCheckSearchForm";
import useMarketActions from "../hooks/useMarketActions";
import {Alert} from "react-bootstrap";
import {titleMarginBottom} from "../constants/layout";
import Divider from "../components/Divider";
import Badge from "react-bootstrap/Badge";
import CompanyReport from "../components/CompanyMonitor/CompanyReport";
import CompanyReportCompanyCard from "../components/CompanyMonitor/CompanyReportCompanyCard";
import Card from "../components/Card"
import {Colors} from "../constants/colors";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DetailsCard from '../components/DetailsCard';


const CompanyCheck = () => {
  const styles = useStyles()

  const companyCheckLoading = useAppSelector(state => state.companyCheck.loading)
  const companyCheckError = useAppSelector(state => state.companyCheck.error)
  const latestCompanyChecks = useAppSelector(state => state.companyCheck.latestCompanyChecks)
  const markets = useAppSelector(state => state.market.markets)
  const marketsLoading = useAppSelector(state => state.market.loading)

  const [currentCompanyCheck, setCurrentCompanyCheck] = useState<CompanyCheckResponseModel>()
  const {getLatestCompanyChecks, performCompanyCheck} = useCompanyCheckActions()
  const {getMarkets} = useMarketActions()

  const [activeKey, setActiveKey] = useState<string | undefined>("0");

  useEffect(() => {
    getLatestCompanyChecks()
    if (markets.length === 0) {
      getMarkets()
    }
  }, [])

  const openSearchResults = () => {
    setActiveKey("0")
  }

  const closeSearchResults = () => {
    setActiveKey(undefined)
  }

  const onSearchClicked = (request: CompanyCheckRequest) => {
    performCompanyCheck(request)
      .then(response => {
        setCurrentCompanyCheck(response)
      })
    closeSearchResults()
  }

  const onCompanyCheckClicked = (item: CompanyCheckResponseModel) => {
    setCurrentCompanyCheck(item)
    closeSearchResults()
  }

  const renderCompanyCheckDetails = (item: CompanyCheckResponseModel | undefined) => {
    if (!item) return null

    return (
      <Card className={`${styles.card} card`}>
        <div className={styles.cardHeader}>
          <h4 className={styles.allCaps}>{item.country} {item.companyRegistrationNumber}</h4>
        </div>
        <div className={styles.cardBody}>
          <DetailsCard>
            {item.company && <CompanyReportCompanyCard company={item.company}/>}
            {item.report && <CompanyReport report={item.report}/>}
            {item.errorMessage && <Alert variant="danger">{item.errorMessage}</Alert>}
          </DetailsCard>
        </div>
        <div className={styles.cardHeader}>
          <div className={styles.badgeContainer}>
            <Badge variant={item.success ? "success" : "danger"}>
              {item.success ? "Success" : "Failed"}
            </Badge>
            <Badge variant="secondary">
              {item.report ? `Report date: ${formatDateTimeString(item.report.date)}` : "No report"}
            </Badge>
            <Badge variant="secondary">
              {`Checked at: ${formatDateTimeString(item.checkedAt)}`}
            </Badge>
          </div>
        </div>
      </Card>
    )
  }

  return (
    <div>
      <h1 style={{marginBottom: titleMarginBottom}}>Company Check</h1>

      <Accordion activeKey={activeKey}>
        <Card className={`${styles.card} card`}>
          <div className={styles.cardBody} onFocus={openSearchResults}>
            <h5>Search and create report</h5>
            <CompanyCheckSearchForm markets={markets} onSearch={onSearchClicked}/>
            <Alert style={{marginTop: 10}} variant="danger" show={companyCheckError !== null}>
              {companyCheckError?.toString()}
            </Alert>
          </div>
          <Accordion.Collapse eventKey="0">
            <div className={styles.cardBody}>
              <h5>Search history</h5>
              <CompanyCheckList companyChecks={latestCompanyChecks} onClick={onCompanyCheckClicked}/>
            </div>
          </Accordion.Collapse>
          <div
            className={styles.accordionController}
            onClick={activeKey === "0" ? closeSearchResults : openSearchResults}>
            <FontAwesomeIcon icon={activeKey === "0" ? faChevronUp : faChevronDown} color={Colors.white}/>
          </div>
        </Card>
      </Accordion>
      <Divider/>
      {renderCompanyCheckDetails(currentCompanyCheck)}
      <FullScreenLoadingIndicator visible={companyCheckLoading || marketsLoading}/>
    </div>
  )
}

export default CompanyCheck

const useStyles = createUseStyles({
  card: {
    backgroundColor: Colors.darkGrayCard,
    borderColor: Colors.darkGrayBorder,
    borderWidth: 2,
    borderRadius: 8,
    display: 'flex',
    overflow: "hidden",
    gap: 8,
  },
  cardHeader: {
    backgroundColor: Colors.grayCard,
    padding: 14
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 14
  },
  badgeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: 10,
    flexWrap: "wrap",
    fontFamily: 'Aestetico-Regular',
    fontSize: "large"
  },
  allCaps: {
    textTransform: "uppercase"
  },
  accordionController: {
    display: 'flex',
    justifyContent: 'center',
    padding: 4,
    backgroundColor: Colors.grayCard,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  }
})
