import Card from '../Card'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../constants/colors'
import {CompanyModel} from '../../redux/companyMonitorTypes';
import {faBuilding} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  company: CompanyModel,
}

const CompanyReportCompanyCard = ({company}: Props) => {
  const styles = useStyles()

  return (
      <Card className={`${styles.card} card`}>
        <div className={styles.cardRow}>
          <FontAwesomeIcon className={styles.largeIcon}
                           icon={faBuilding}
                           color={Colors.white}
          />
          <div>
            <h5>{company.name}</h5>
            <h6 className={styles.allCaps}>{company.country} {company.companyRegistrationNumber}</h6>
          </div>
        </div>
      </Card>
  )
}
export default CompanyReportCompanyCard

const useStyles = createUseStyles({
  card: {
    backgroundColor: '#4B4B4B',
    display: 'flex',
    flex: 1,
    borderRadius: 8,
    padding: 14,
    gap: 8
  },
  cardRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20
  },
  largeIcon: {
    fontSize: "xxx-large"
  },
  allCaps: {
    textTransform: "uppercase",
    marginTop: 5
  }
})
