import Card from '../Card'
import {createUseStyles} from 'react-jss'
import {Colors} from '../../constants/colors'
import {CompanyReportRepresentativeModel} from '../../redux/companyMonitorTypes';
import Badge from "react-bootstrap/Badge";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import ReportModal from "./ReportModal";
import {formatDateString} from "../../utils/dateHelper";

interface Props {
  representative: CompanyReportRepresentativeModel,
}

const CompanyReportRepresentativeCard = ({representative}: Props) => {
  const styles = useStyles()
  const [representativeAmlReportModalVisible, setRepresentativeAmlReportModalVisible] = useState(false)

  const isFlagged = representative.isSanction || representative.isPep || representative.isRca

  const renderPositionBadge = (position: string) => (
    <Badge key={position} variant={"info"}>{position}</Badge>
  )

  return (
    <>
      <Card className={`${styles.card} card ${isFlagged && styles.warning}`}
            onClick={() => setRepresentativeAmlReportModalVisible(true)}>
        <FontAwesomeIcon className={styles.largeIcon}
                         icon={faUser}
                         color={Colors.white}
        />
        <h6>{representative.givenName} {representative.familyName}</h6>
        <p>Birth date: <span>{representative.dateOfBirth ? formatDateString(representative.dateOfBirth) : '-'}</span></p>
        <p>Country: <span style={{textTransform: "uppercase"}}>{representative.country ?? '-'}</span></p>
        <p>Email: <span>{representative.email ?? '-'}</span></p>

        <div className={styles.badgeContainer}>
          {representative.positions.map(renderPositionBadge)}
        </div>

        <div className={styles.badgeContainer}>
          {representative.isSanction && <Badge variant={"danger"}>Sanctioned</Badge>}
          {representative.isPep && <Badge variant={"danger"}>PEP</Badge>}
          {representative.isRca && <Badge variant={"danger"}>RCA</Badge>}
        </div>
      </Card>

      <ReportModal
        title='Company representative AML report'
        visible={representativeAmlReportModalVisible}
        reportContent={representative.amlReport}
        onCloseClick={() => setRepresentativeAmlReportModalVisible(false)}
      />
    </>
  )
}
export default CompanyReportRepresentativeCard

const useStyles = createUseStyles({
  card: {
    height: '100%',
    backgroundColor: '#4B4B4B',
    display: 'flex',
    flex: 1,
    borderRadius: 8,
    padding: 14,
    gap: 0,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  warning: {
    boxShadow: '0 0 5px 0 #FF3333',
    '&:hover': {
      boxShadow: '0 0 30px 0 #FF3333',
    }
  },
  badgeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
    fontFamily: 'Aestetico-Regular',
    fontSize: "large"
  },
  largeIcon: {
    fontSize: "xxx-large",
    marginBottom: 8
  },
})
